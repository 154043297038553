import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const InfoBlock = makeShortcode("InfoBlock");
const Box = makeShortcode("Box");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "🦉-beautiful-wisdom",
      "style": {
        "position": "relative"
      }
    }}>{`🦉 Beautiful Wisdom`}<a parentName="h1" {...{
        "href": "#%F0%9F%A6%89-beautiful-wisdom",
        "aria-label": "🦉 beautiful wisdom permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <h2 {...{
      "id": "sermo-liber-vita-ipsa",
      "style": {
        "position": "relative"
      }
    }}>{`Sermo Liber Vita Ipsa`}<a parentName="h2" {...{
        "href": "#sermo-liber-vita-ipsa",
        "aria-label": "sermo liber vita ipsa permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`‘Mettā’ is a Sanskrit word which means benevolence, loving-kindness, good will, active interest in others. This relational and compassionate focus represents the heart of how we think free education can revivify our common understanding of what is good, beautiful, and true. It also recalls the Greek word ‘meta’, meaning beyond, as in ‘metaphor’ which means ‘to carry beyond’. Here, hopefully, you may find loving words which carry meaning beyond what you think language can represent.`}</p>
    <p>{`This module elucidates the roots of our underlying educational practices and philosophy. How is it that we can learn together–through dialogue–to do the right thing, at the right time, in the right way, to the right degree? What conversations do we need to enter into in order to experience shared purpose and virtue? Where might we find the wisdom we need to live wholly in the world and to love well?`}</p>
    <p>{`To open our exploration, we turn to two people who spent their lives actively interested in others as a means of cultivating personal wisdom: Ivan Illich and E. F. Schumacher. We will describe in detail why deschooling society is valuable, how we can do so most simply, and what effect this will have on the economic organisation of the world. We will move from the institutionalisation of singular ‘treatments’ or solutions, to a plurality of personal experiences; from scale as in growth, to scales as in balance and justice.`}</p>
    <p>{`Finally, we will close by approaching the same ideas from another root. We'll turn to the latest thinking in Quantum Gravity, coupled with some fundamental wisdom from the middle way, to emphasise more deeply why it is that we place such specific focus on the ways we relate.`}</p>
    <InfoBlock mdxType="InfoBlock">
      <Box mdxType="Box">
        <p>{`School makes the child,`}<br parentName="p"></br>{`
`}{`jail the prisoner,`}<br parentName="p"></br>{`
`}{`armies the soldier,`}<br parentName="p"></br>{`
`}{`asylums the psychotic,`}<br parentName="p"></br>{`
`}{`nursing homes the aged.  `}</p>
        <p>{`We can just as well`}<br parentName="p"></br>{`
`}{`act in a different world,`}<br parentName="p"></br>{`
`}{`made other-wise`}<br parentName="p"></br>{`
`}{`in knowing our self.  `}</p>
        <p>{`We can sing the script`}<br parentName="p"></br>{`
`}{`rather than speak it,`}<br parentName="p"></br>{`
`}{`we can learn how to stop`}<br parentName="p"></br>{`
`}{`and see the relationship`}<br parentName="p"></br>{`
`}{`this bears to `}<a parentName="p" {...{
            "href": "/learn/module-7/perfection/#algorithms-and-information"
          }}>{`regeneration`}</a>{`,`}<br parentName="p"></br>{`
`}{`a radical spiritual change,`}<br parentName="p"></br>{`
`}{`an altered kind of breath`}<br parentName="p"></br>{`
`}{`which brings us home  `}</p>
        <p>{`here`}<br parentName="p"></br>{`
`}{`where we are already`}<br parentName="p"></br>{`
`}{`in love.  `}</p>
      </Box>
    </InfoBlock>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      